@import "./variables";
@import "./mixins";

.calling {
    color: $basic-1;
    font-family: 'Radio Canada Big', sans-serif;
    list-style: none;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    padding-left: 20%;
    padding-right: 20%;
    @include mobile {
        display: flex;
        align-content: center;
        padding-left: 20px;
        padding-right: 5px;
        margin: 10px;
        font-size: 1em;

    }
}
  a{
       &:hover {
    color: $blue-500;
    font-weight: 800;
    } 
    }
.social {
    display: flex;
    justify-content: center;
    gap: 50px;
    .social-img {
    margin-top: 10px;
    width: 35px;
} 
}

