@import "./_mixins.scss";


header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 18vh;
    background-color: $header-background;
    background-size: 100%;
        .logo {
            margin-left: auto;
            margin-right: auto;
            padding-top: 10px;
            width: 10%;
            text-align: center;
        }
}
