@import "./variables";
@import "./mixins";


.title{
font-family: 'Space Mono', monospace ;
font-size: 4rem;
font-weight: 800;
color: $basic-1;
position: relative;
display: flex;
justify-content: center;
padding-bottom: 20px;
@include mobile {
    font-size: 1.8em;
}

}
.gallery {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
@include mobile {
    display: inline-flexbox;
    justify-content: center;
    align-items: center;
    padding-left: 20%;
    margin: auto;
}

    img {
        width: 20%;
        margin-right: 3rem;
        margin-bottom: 3rem;
        &:nth-child(4) {
            width: 10%;
        }
    }
}