@import "./variables";

.contacts { 
    color: $basic-1;
    font-family: 'Radio Canada Big', sans-serif;
    font-size: 0.8rem;
    list-style: none;
    margin-left: 20px;
    margin-top: 20px;
    .logo {
            width: 5%;
            text-align: center;
            margin-bottom: 1rem;
        }

}