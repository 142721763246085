@import "mixins";
@import "variables";

footer {
    .row {
        margin-bottom: 4rem;
        border-top: 3px solid $blue-500;

        @include mobile {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            text-align: center;

            nav {
                text-align: center;
                margin-top: 1rem;
                display: inline-block;
            }
        }

        &:nth-child(1) {
            padding-bottom: 3rem;
        }
    }
} 
.logo {
            margin-left: auto;
            margin-right: auto;
            padding-top: 10px;
            width: 10%;
            text-align: center;
}
.basics {
    display: inline;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
}

.copyright {
                margin-top: 2rem;
                font-size: 0.7rem;
                position: relative;
                display: flex;
                align-items: end;
                justify-content: center;
            .colorful {
                color: $blue-500;
            }
}