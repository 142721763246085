@import "./variables";

@mixin mobile {
    @media (max-width: $mobile) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: $tablet) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop) {
        @content;
    }
}