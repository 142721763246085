@import "./variables"; 
@import "./mixins";

.gallery {
    position: relative;
    width: 100%;
    margin: auto;
    padding-bottom: 30px;
}

.gallery-container {
    background-image: url(../img/TILEN_Design-01.svg);
    background-position-x: 100%;
    background-position-y: 8vh;
    background-repeat: no-repeat;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        margin: 10px;
        padding: 20px;
    @include mobile{
        background: none;
        align-items: center;
        width: 100%;
    }

        .gallery-img{
        background-color: $basic-1;
            object-fit: cover;
            width: 25vh;
            height: 25vh;
            padding: 10px;
            margin-top: 30px;
            margin-right: 30px;
            border-radius: 50%;
            @include mobile {
                    display: flex;
                    align-items: center;
                    padding-right: 10px;
                    margin-left: 30px;
                }
        }
        .gallery-titles {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-size: 1.4em;
            font-style: bold;
            padding: 20px 10px 20px 10px;
            border-radius: 10px;
            position: relative;
            justify-content: space-evenly;
            text-align: center;
            margin-top: -110px;
            margin-left: 10px;
            margin-right: 10px;
            color: $basic-1;
            background-color: $blue-400;
            @include mobile {
                    display: flex;
                    align-items: center;
                    font-size: 1em;
                    margin-top: -70px;
                    padding: 10px 5px 10px 5px;
                }
        }
    } 