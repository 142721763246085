$blue-100: #399f99;
$blue-300: #047c84;
$blue-400: #229C93;
$blue-500: #008186;
$green-100: #61847c;
$background-color: #1f2c26;
$header-background: #0c110f;

$basic-1: #ffffff;
$basic-2: #040404;


$desktop: 1200px;
$tablet: 800px;
$mobile: 799px;