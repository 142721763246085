@import "./variables";
@import "./mixins";

nav {
    color: $basic-1;
    
}
.menu {
    display: flex;
    justify-content: center;
    padding: 20px;
    margin: 10px;
    gap: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2em;
    font-style: bold;
    @include mobile {
        display: inline;
        justify-content: space-around;
        height: 20vh;
        padding: 5px;
        margin: 20px;
        font-size: 0.8em;
    }
}


a{
    text-decoration: none;
    color: $basic-1;
    &:hover{
        background-color: $blue-400;
        border-radius: 20px;
        color: $basic-1;
        padding: 10px;
        @include mobile {
            padding: 5px;
            margin: 10px;
        }
    }
}



       
 