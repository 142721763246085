@import "./variables";
@import "./mixins";

.gremo-na-kavo {
    margin-bottom: 40px;
}
.table{
    display: flex;
    justify-content: center;
    align-items: center;

}
.title-2{
    font-family: "Space Mono", monospace;
        font-size: 4rem;
        font-weight: 800;
        color: #ffffff;
        padding-bottom: 20px;
        @include mobile {
            font-size: 2em;
        }
    }
.form{
    width: 50%;
    @include mobile{
        width: 100%;
    }
}
#contactForm {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    @include mobile {
        display: flex;
        justify-content: start;
        align-items: start;

    }
    label {
        color: $basic-1;
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        font-size: 1.3rem;
        margin-bottom: 10px;
        @include mobile {
            font-size: 1em;
        }
    }
    .name{
        display: flex;
        flex-direction: column;
    }
    .company{
        display: flex;
        flex-direction: column;
    }
    .email {
        display: flex;
        flex-direction: column;
    }
    .phone {
        display: flex;
        flex-direction: column;
    }
    .message {
        display: flex;
        flex-direction: column;
    }
    input {
        color: $basic-1;
        font-weight: 700;
        height: 40px;
        width: 30vh;
        background: transparent;
        border: none;
        border-bottom: 2px solid $blue-500;
        margin-bottom: 10px;
        @include mobile {
            justify-content: center;
            padding: 0;
            margin: 0;
        }
        &:hover{
            background: linear-gradient(45deg, $blue-400, $basic-2);
            border-radius: 10px;
            opacity: .8;
        }
    }
    textarea {
        background: none;
        color: $basic-1;
        background: transparent;
        border: none;
        border-bottom: 2px solid $blue-500;
        width: 50vh;
        &:hover {
        opacity: .8;
        background: linear-gradient(90deg, $blue-400, $basic-2);
        border-radius: 10px;  
        @include mobile {
            width: 40vh;
        }
        }
    }
button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: static;
    margin-top: 15px;
    width: 18vw;
    padding: 15px;
    border-radius: 50px;
    @include mobile {
        border-radius: 50px;
        width: 50vw;
    }

}
.oddaj {
    color: $basic-1;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 1.3rem;
    background-color: $blue-500;
}
}

.photo {
    width: 50%;
    .photo-ekipa{
        border-radius: 30px;
    }
    @include mobile{
        width: 0;
    }
}