@use 'scss-reset/reset';

@import "./variables";
@import "./mixins";

html,
body {
    background-color: $header-background;
    font-size: 16px;
    p {
            color: $basic-1;
            font-family: "Roboto", sans-serif;
        }
    
        .container {
            width: 1200px;
            margin-left: auto;
            margin-right: auto;
    
            @include tablet {
                width: 100%;
            }
    
            @include mobile {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }