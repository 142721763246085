@import "./variables";
@import "./mixins";

.title {
    font-family: 'Space Mono', monospace;
    font-size: 4rem;
    font-weight: 800;
    color: $basic-1;
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 20px;
    @include mobile{
        font-size: 2em;
        align-items: center;
    }
}

.galleryVideo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    @include mobile {
        align-items: center;
        gap: 10px

    }
}

.video {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 4vw;
    padding-bottom: 4vw;
    align-items: center;
    border-radius: 30px;
    @include mobile {
        display: block;
        justify-content: center;
        align-content: center;
    }
    
    .srcImg {
        background-color: $blue-400;
        background-size: 200px 200px;
        width: 25vh;
        height: 25vh;
        padding: 10px;
        border-radius: 50%;
    }
    .titleParagraph{
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-size: 1.4em;
            font-style: bold;
            padding: 20px 10px 20px 10px;
            border-radius: 10px;
            position: relative;
            text-align: center;
            margin-top: -5px;
            margin-left: -80px;
            margin-right: 10px;
            color: $basic-1;
            background-color: $blue-400;
            @include mobile {
                font-size: 1em;
                padding: 10px;
                margin-left: 20px;
            }
    }

}

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $basic-2;
    justify-content: center;
    align-items: center;
}

.modal iframe {
    width: 80%;
    height: 80%;
}

.close {
    position: absolute;
    top: 20px;
    right: 30px;
    color: white;
    font-size: 30px;
    cursor: pointer;
}