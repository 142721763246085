@import "./variables";
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');


.wrapper {
    display: contents;
    position: fixed;
    h1{
    font-family: 'Space Mono', monospace;
    font-weight: 600;
    font-size: clamp(3rem, 10vw, 10rem);
    color: $basic-1;
    text-transform: uppercase;  
    }
    
}